<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row" id="first">
        <div class="col-12">
          <h1>{{ locale[lang].GUIDE_M.TOP.TITLE }}</h1>
          <p v-html="locale[lang].GUIDE_M.TOP.SUBTITLE">
          </p>
        </div>
      </div>

      <template v-if="!mobile">
        <div class="row" id="checks">
          <div class="col-6" id="col-check">
            <h1>{{locale[lang].GUIDE_M.CHECKS.TITLE}}</h1>
            <div class="check" v-for="(check, key) in locale[lang].GUIDE_M.CHECKS.ITEMS" :key="key" data-aos="fade-right">
              <img class="img-fluid" src="@/assets/Images/Practice/Icon awesome-check-circle.png" alt="check" />
              <p>
                {{ check }}
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="embed-responsive embed-responsive-4by3">
              <iframe class="embed-responsive-item" :src="locale[lang].GUIDE_M.LINK_VIDEO" frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="row" id="checks">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-4by3">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Ku3rGLc3CjE" frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
          </div>
          <div class="col-12" id="col-check">
            <div class="title-check">
              <h1>{{locale[lang].GUIDE_M.CHECKS.TITLE}}</h1>
            </div>
            <div class="check" v-for="(check, key) in locale[lang].GUIDE_M.CHECKS.ITEMS" :key="key" data-aos="fade-right">
              <img class="img-fluid" src="@/assets/Images/Practice/Icon awesome-check-circle.png" alt="check" />
              <p>
                {{ check }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <div class="row" id="guide">
        <div class="col-12">
          <div class="row" data-aos="fade-right">
            <div class="col-12 title">
              <h1>
                {{ locale[lang].GUIDE_M.LA_GUIDE.TITLE }}
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <img class="img-fluid" src="@/assets/Images/Practice/Imagen 119.png" alt="guide-img" />
            </div>
          </div>
          <div class="row two-col">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 first-col" data-aos="fade-right">
              <h1>{{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_1_1.TITLE }}</h1>
              <p>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_1_1.TEXT }}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 second-col" data-aos="fade-left">
              <h1>{{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_1_2.TITLE }}</h1>
              <p>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_1_2.TEXT }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="management">
              <h1>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.TEXT_1 }}
              </h1>
            </div>
          </div>
          <div class="row two-col">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 first-col" data-aos="fade-right">
              <h1>{{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_2_1.TITLE }}</h1>
              <p>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_2_1.TEXT }}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 second-col" data-aos="fade-left">
              <h1>{{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_2_2.TITLE }}</h1>
              <p>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.COL_2_2.TEXT }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="leader">
              <h1>
                {{ locale[lang].GUIDE_M.LA_GUIDE.CONTENT.TEXT_2 }}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="paypal">
        <ProductShop :programme="true" :change="locale[lang].CHANGE_G" :info="locale[lang].GUIDE_M.INFOSHOP" />
      </div>

      <div class="row" id="numbers">
        <div class="col-12">
          <div class="row">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{locale[lang].GUIDE_M.MODULES.TITLE}}</h1>
            </div>
            <div class="col-12 subtitle" data-aos="fade-right">
              <p>
                {{locale[lang].GUIDE_M.MODULES.SUBTITLE}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-12 col-12">
              <div class="row">
                <div v-for="number in numbers" :key="number.id" class="col-lg-12 col-md-12 col-sm-2 col-2 number"
                  :class="{ active: selected === number.id }" @click="changeModule(number.id)">
                  <img v-if="selected === number.id" class="img-fluid"
                    :src="require('@/assets/Images/Practice/' + number.numberRed)"
                    :alt="'number' + number.id + 'red'" />
                  <img v-else :src="require('@/assets/Images/Practice/' + number.number)" :alt="'number' + number.id" />
                </div>
              </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 col-12">
              <div class="row">
                <div class="col-12">
                  <div class="module-content" v-html="content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="satisfaction">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="garantie">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].GARANTIE_IMG)" alt="garantie" />
          <h2>{{ locale[lang].GUIDE_M.GARANTIE.TITLE }}</h2>
          <p>
            {{ locale[lang].GUIDE_M.GARANTIE.SUBTITLE }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="paiement">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].PAY_IMG)" alt="paiement" />
          <h2>{{ locale[lang].GUIDE_M.PAIEMENT.TITLE }}</h2>
          <p>
            {{ locale[lang].GUIDE_M.PAIEMENT.SUBTITLE }}
          </p>
        </div>
        <div class="col-12" id="page">
          <h3>
            {{ locale[lang].GUIDE_M.CONTACT.TEXT_1 }}
            <br />
            {{ locale[lang].GUIDE_M.CONTACT.TEXT_2 }}
            <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{ locale[lang].GUIDE_M.CONTACT.LINK }}</a>
          </h3>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import ProductShop from "@/components/ProductShop.vue";
import defaultMixin from "@/mixins/defaultMixin";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  mixins: [defaultMixin],
  components: {
    ProductShop,
    NavBar,
    Footer,
  },
  created() {
    this.content = this.locale[this.lang].GUIDE_M.MODULES.INITIAL;
  },
  data() {
    return {
      mobile: false,
      selected: 1,
      content: ``,
      numbers: [
        {
          id: 1,
          number: "1.png",
          numberRed: "1-red.png",
        },
        {
          id: 2,
          number: "2.png",
          numberRed: "2-red.png",
        },
        {
          id: 3,
          number: "3.png",
          numberRed: "3-red.png",
        },
        {
          id: 4,
          number: "4.png",
          numberRed: "4-red.png",
        },
        {
          id: 5,
          number: "5.png",
          numberRed: "5-red.png",
        },
      ],
    };
  },
  methods: {
    changeModule(number) {
      this.selected = number;
      for (const module of this.locale[this.lang].GUIDE_M.MODULES.ITEMS) {
        if (module.id === number) {
          this.content = module.content;
        }
      }
    },
  },
};
</script>

<style scoped>
#first {
  background-image: url("../assets/Images/Training/Trazado 2793.png"),
    url("../assets/Images/Training/Enmascarar grupo 174.png");
  background-size: auto;
  background-repeat: no-repeat;
  text-align: left;
  background-position: 85% 50%, 0 115%;
  padding: 5% 10% 10% 10%;
}

#nav-container {
  background-color: #0f1010!important;
}

h1 {
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

h2 {
  text-align: left;
  font: normal normal 800 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

p {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
}

#first h1 {
  text-align: left;
  color: #ffffff;
  margin-bottom: 32px;
}

#first p {
  text-align: left;
  font: normal normal 500 26px/38px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 50px;
}

#checks {
  padding: 5% 8%;
}

#checks h1,
#guide h1,
#end h1 {
  color: #0f1010;
}

#col-check {
  padding-right: 4%;
}

#checks .check {
  display: flex;
  margin-bottom: 10px;
}

#checks h1 {
  text-align: left;
  margin: 0 0 20px 8%;
}

.check img {
  height: 46px;
  margin-right: 16px;
}

#checks video {
  mix-blend-mode: multiply;
  border-radius: 15px;
}

#checks .embed-responsive {
  height: 80%;
}

#checks .embed-responsive .embed-responsive-item,
#checks .embed-responsive iframe {
  border-radius: 30px;
}

#guide {
  background-image: url("../assets/Images/Practice/Trazado 2888.png"),
    url("../assets/Images/Practice/Elipse 188.png"),
    url("../assets/Images/Practice/Enmascarar grupo 187.png"),
    url("../assets/Images/Practice/Enmascarar grupo 188.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 4% 2%, 92% 28%, 100% 63%, 100% 100%;
  padding-bottom: 5%;
}

#guide .title {
  margin-bottom: 4%;
  padding: 0 17%;
}

.title {
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 1;
  bottom: -25px;
}

#guide .title h1 {
  text-transform: uppercase;
  text-align: center;
}

#guide h1 {
  text-align: left;
}

#guide .two-col {
  padding: 5% 12.5%;
}

#guide .first-col {
  padding-right: 5%;
  border-right: 2px solid rgba(112, 112, 112, 0.5);
  height: 170px;
}

#guide .second-col {
  padding-left: 5%;
}

#guide #management {
  padding: 8% 10% 6% 12.5%;
}

#guide #leader {
  padding: 8% 10% 10% 12.5%;
}

#paypal {
  background-image: url("../assets/Images/Practice/Grupo 1084.png"),
    url("../assets/Images/Practice/Grupo 1083.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 4% 0, 96% 92%;
  padding: 4% 12% 10% 12%;
}

#numbers {
  padding: 0 10%;
}

#numbers .title {
  margin-bottom: 4%;
}

#numbers .title h1 {
  text-transform: uppercase;
  text-align: center;
  color: #0f1010;
}

#numbers .subtitle {
  margin-bottom: 4%;
}

#numbers .subtitle p {
  text-align: center;
}

#numbers .col-11 {
  padding-left: 5%;
}

#numbers >>> .module-content h4 {
  text-align: left;
  font: normal normal 800 30px/30px Montserrat;
  letter-spacing: 0.6px;
  color: #242424;
  margin-bottom: 30px;
  margin-top: 10px;
}
#numbers >>> .module-content p {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
}

#numbers >>> .module-content p.gray {
  color: #9b9b9b;
  margin-top: 50px;
  margin-bottom: 25px;
}

#numbers >>> .module-content p.gray:nth-child(2) {
  margin-top: 0;
}

.number {
  border-right: 4px solid #dfdfdf;
}

.number.active {
  border-right-color: #ff0313;
}

.number img {
  cursor: pointer;
}

#satisfaction {
  background-image: url("../assets/Images/Practice/Enmascarar grupo 149.png"),
    url("../assets/Images/Cycles/Enmascarar grupo 149.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 0, 50% 100%;
  padding: 14% 0%;
  margin-top: 60px;
}

#satisfaction #garantie {
  padding-left: 12%;
  padding-right: 6%;
  position: relative;
}

#satisfaction #garantie::after {
  content: "";
  position: absolute;
  height: 75%;
  left: 99%;
  top: 20%;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

#satisfaction #paiement {
  padding: 7% 8% 0 5%;
}

#satisfaction h2 {
  margin: 27px 0;
}

#end {
  background-image: url("../assets/Images/Practice/Grupo 1085.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 89% 70%;
  padding: 5% 8%;
}

.end p,
#satisfaction p {
  color: #ffffff !important;
}

#satisfaction p,
h2 {
  text-align: center;
}

#page {
  padding: 8% 10% 0 10%;
}

#page h3 {
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
}

#page h3 a {
  color: #f9334a;
}

#end .col-6:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-sc {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #ffffff;
  /* border: 3px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  font: normal normal bold 26px/32px Montserrat;
  color: #0f1010;
  border-radius: 15px;
  padding: 28px 214px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
}

.btn-sc:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 20px;
}

@media (max-width: 1600px) {
  #page {
    padding-top: 12%;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 18px;
    line-height: 22px;
  }

  .col-lg-6, .col-lg-1 {
      flex: 0 0 100%;
      max-width: 100%;
    }

  #first {
    background-image: url("../assets/Images/Cycles/Enmascarar grupo 163.png");
    background-size: 100% 145%;
    background-repeat: no-repeat;
    background-position: 0 -100px;
    padding: 0% 5% 10% 5%;
  }

  #first h1 {
    text-align: center;
    text-transform: uppercase;
  }

  #first p {
    text-align: center;
    font: normal normal 300 16px/18px Montserrat;
  }

  #checks,
  #guide .two-col {
    padding: 5%;
  }

  #checks h1 {
    text-align: center;
    margin: 48px 0;
  }

  #checks .title-check {
    position: relative;
  }

  #checks .title-check::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    left: 40%;
    opacity: 1;
    bottom: -100%;
  }

  #checks .check img {
    height: 31px;
    width: 31px;
  }

  #guide #management h1,
  #guide #leader h1 {
    font-size: 14px;
  }

  #checks .check p,
  #guide p,
  #numbers .subtitle p,
  #numbers >>> .module-content p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.24px;
  }

  #guide {
    background-image: url("../assets/Images/Practice/Enmascarar grupo 194.png"),
      url("../assets/Images/Practice/Enmascarar grupo 195.png");
    background-size: 100% 14%;
    background-repeat: no-repeat;
    background-position: 0 59%, 0 100%;
    padding-bottom: 12%;
  }

  #guide .title {
    margin-bottom: 50px;
    padding: 0 5%;
  }

  #guide h1 {
    font-size: 18px;
    line-height: 19px;
  }

  #guide h1,
  #guide p {
    text-align: center;
  }

  #guide p {
    margin-bottom: 30px;
  }

  #guide .first-col {
    border-right: none;
    border-bottom: 1px solid #a1a1a1;
    height: auto;
    margin-bottom: 40px;
  }

  #guide #management {
    padding: 14% 10% 18% 12.5%;
  }

  #guide #leader {
    padding: 10% 10% 0% 12.5%;
  }

  #paypal {
    background-size: 30%;
    padding: 7% 5%;
  }

  #page {
    padding: 38% 9% 8%;
  }

  #page h3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
  }

  #numbers {
    padding: 10% 5%;
  }

  #numbers .title {
    padding: 0;
    margin-bottom: 45px;
  }

  .title::after {
    bottom: -15px;
  }

  #numbers .row {
    justify-content: center;
  }

  #numbers >>> .module-content h4 {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 28px;
  }

  #numbers >>> .module-content p.gray {
    text-align: center;
  }

  #numbers >>> .module-content p.gray:nth-child(2) {
    margin-top: 25px;
  }

  #numbers >>> .module-content p {
    letter-spacing: 0.24px;
  }

  .number {
    border-right: none;
    background-color: #eeeeee;
    border-radius: 5px;
    max-width: 70px !important;
    padding: 0;
    margin-right: 14px;
  }

  .number:nth-child(5) {
    margin-right: 0;
  }

  .number.active {
    border: 1px solid #ff0313;
  }

  .number img {
    height: 61px;
  }

  #satisfaction {
    background-image: url("../assets/Images/Cycles/Satisfaction Garantie.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }

  #satisfaction #paiement {
    padding: 0 8% 0 5%;
  }

  #satisfaction #garantie {
    padding: 0 6%;
    margin-bottom: 50px;
  }

  #satisfaction #garantie::after {
    border-right: 0;
  }

  #satisfaction #garantie h2 {
    font-size: 18px;
    line-height: 22px;
  }

  #paiement img {
    max-width: 50%;
  }

  #paiement h2 {
    font-size: 13px;
    line-height: 26px;
  }

  #end {
    background-image: none;
    padding: 10% 0;
  }

  #end h1 {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.24px;
    color: #8a8a8a;
  }

  #end .btn-sc {
    margin-top: 24px;
    font: normal normal bold 16px/16px Montserrat;
    padding: 17px 87px;
  }
}

@media (max-width: 425px) {
  #guide {
    background-position: 0 54%, 0 100%;
    padding-bottom: 5%;
  }

    #guide #leader {
    padding: 16% 10% 11.5% 12.5%;
  }
}

@media (max-width: 375px) {
  #guide {
    background-position: 0 53%, 0 99%;
  }
}
</style>
