<template>
  <div class="container-fluid">
    <div class="row" id="card">
      <div class="col-12">
        <div class="text-card">
          <h1>
            {{ info.title }}
          </h1>
          <img id="photo" class="img-fluid mb-5" :src="info.img" alt="item-foto" />
          <p v-if="programme">
            {{ locale[lang].PRODUCT_SHOP.CONTENT.TEXT_1 }} {{ change }} {{ locale[lang].PRODUCT_SHOP.CONTENT.TEXT_2 }}
          </p>
        </div>
        <div class="button-shop mt-5">
          <button class="btn btn-sc" @click="goShop">{{ locale[lang].PRODUCT_SHOP.BUTTON }}</button>
        </div>
        <div class="text-card end mt-5">
          <p>
            <i
              >{{ locale[lang].PRODUCT_SHOP.END }}</i
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    programme: Boolean,
    change: String,
  },
  methods: {
    goShop() {
      window.open(this.info.link, "_blank");
    },
  },
};
</script>

<style scoped>
#card {
  background-image: url("../assets/Images/Idees/graf.png");
  background-color: #0f1010;
  background-repeat: no-repeat;
  background-position: 0 0, 0 0;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 40px;
  opacity: 1;
  padding: 5% 0;
}

.text-card {
  padding: 0 10%;
}

#card h1 {
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 70px;
}

#card p {
  text-align: center;
  font: normal normal 500 22px/40px Montserrat;
  letter-spacing: 0.44px;
  color: #ffffff;
  opacity: 1;
}

#card .end p{
  color: #d3d3d3;
}

#card #photo {
  border-radius: 30px;
}

#card .btn-sc {
  width: 70%;
}

.btn-sc {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: #0f1010;
  border: 3px solid #ffffff !important;
  font: normal normal bold 22px/27px Montserrat;
  color: #ffffff;
  border-radius: 15px;
  padding: 24px 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  height: 85px;
}

.btn-sc:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 20px;
}

@media (max-width: 825px) {
  #card {
    background-size: 50%;
    padding: 12% 0;
  }
  #card h1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 23px;
  }

  #card p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
    margin-bottom: 5px;
  }
  #card .text-card {
    padding: 0;
  }

  #card .end {
    padding: 0 5% !important;
  }

  #card .end p {
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
}
</style>
